import TableRow from '@mui/material/TableRow';
import { Collapse, TableCell } from '@mui/material';
import ResolutionDossier from '../../../../domain/automator/resolutionDossiers/ResolutionDossier';
import { forwardRef } from 'react';
import { ResolutionDossierInfoTableRow } from './ResolutionDossierInfoTableRow';
import { ResolutionCaseView } from './ResolutionCaseView';
import { useNavigate } from 'react-router-dom';
import { usePaths } from '../../../../routes/usePaths';
import { ResolutionDossierStatus } from '../../../../domain/automator/resolutionDossiers/ResolutionDossierStatus';

interface ResolutionDossierTableRowProps {
  resolutionDossier: ResolutionDossier;
  isSelected: boolean;
  selectedCaseId: number | null;
  isFirst: boolean;
  isLast: boolean;
  selectedStatus: ResolutionDossierStatus;
}

export const ResolutionDossierTableRow = forwardRef<
  HTMLTableRowElement,
  ResolutionDossierTableRowProps
>(
  (
    {
      resolutionDossier,
      isSelected,
      isFirst,
      isLast,
      selectedCaseId,
      selectedStatus,
    }: ResolutionDossierTableRowProps,
    ref
  ) => {
    const path = usePaths();
    const navigate = useNavigate();

    const onSelectCaseId = (resolutionCaseId: number | null) => {
      if (resolutionCaseId === selectedCaseId || !resolutionCaseId) {
        navigate(`${path.automator.resolution_dossiers}`);
      } else {
        navigate(
          `${path.automator.resolution_dossiers}/${resolutionDossier.id}/cases/${resolutionCaseId}`
        );
      }
    };

    return (
      <>
        <ResolutionDossierInfoTableRow
          resolutionDossier={resolutionDossier}
          setSelectedCaseId={onSelectCaseId}
          selectedCaseId={selectedCaseId}
          isSelected={isSelected}
          isFirst={isFirst}
          isLast={isLast}
          selectedStatus={selectedStatus}
        />

        {isSelected && (
          <TableRow ref={ref}>
            <TableCell
              style={{ padding: 0 }}
              colSpan={10}
            >
              <Collapse
                in={isSelected}
                unmountOnExit
              >
                <ResolutionCaseView
                  resolutionCaseId={selectedCaseId!}
                  hasOpenResend={resolutionDossier.hasOpenResend}
                />
              </Collapse>
            </TableCell>
          </TableRow>
        )}

        {isSelected && selectedCaseId && (
          <TableRow style={{ backgroundColor: '#F8F9FA' }}>
            <TableCell colSpan={10}></TableCell>
          </TableRow>
        )}
      </>
    );
  }
);

ResolutionDossierTableRow.displayName = 'ResolutionDossierTableRow';

export default ResolutionDossierTableRow;
