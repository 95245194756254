import { RESOLUTION_DOSSIERS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import ResolutionDossier from '../../../domain/automator/resolutionDossiers/ResolutionDossier';
import { ResolutionDossierQueryKey } from './ResolutionDossierQueryKey';

export const useFetchResolutionDossier = (resolutionDossierId?: number) => {
  const url = useAutomatorApiAccountUrl(RESOLUTION_DOSSIERS_PATH + '/' + resolutionDossierId);
  return useFetchData<ResolutionDossier>(url, ResolutionDossierQueryKey(resolutionDossierId), {
    enabled: !!resolutionDossierId,
  });
};
