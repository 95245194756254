import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import { Scrollbar } from '../../../../../devias/src/components/scrollbar';
import { tokens } from '../../../../locales/translationTokens';
import { TableLoading } from '../../../../components/TableLoading';
import { useTranslation } from 'react-i18next';
import { Fragment, useEffect, useState } from 'react';
import { ResolutionDossierStatus } from '../../../../domain/automator/resolutionDossiers/ResolutionDossierStatus';
import { DateTableListDivider } from '../../returns/components/DateTableListDivider';
import ResolutionDossiersReport from '../../../../domain/automator/resolutionDossiers/ResolutionDossiersReport';
import { ResolutionDossierListTableRows } from './ResolutionDossierListTableRows';
import ResolutionDossier from '../../../../domain/automator/resolutionDossiers/ResolutionDossier';

interface ResolutionDossierListTableProps {
  resolutionCaseId: number | null;
  resolutionDossier: ResolutionDossier | null;
  resolutionDossiersReport: ResolutionDossiersReport | null;
  selectedStatus: ResolutionDossierStatus;
  status: ResolutionDossierStatus;
  isLoading: boolean;
}

export const ResolutionDossierListTable = ({
  isLoading,
  status,
  resolutionDossiersReport,
  resolutionDossier,
  resolutionCaseId,
  selectedStatus,
}: ResolutionDossierListTableProps) => {
  const { t } = useTranslation();

  const determineInitialSelectedDates = () => {
    switch (status) {
      case ResolutionDossierStatus.OPEN:
        return resolutionDossiersReport!.datesWithOpenDossiers;
      case ResolutionDossierStatus.AWAITING_RESPONSE:
        return resolutionDossiersReport!.datesWithAwaitingResponseDossiers;
      case ResolutionDossierStatus.HANDLED: {
        const resolutionCase = resolutionDossier?.resolutionCases.find((resolutionCase) => {
          return resolutionCase.id == resolutionCaseId;
        });

        return resolutionCase ? [resolutionCase!.updatedDate!] : [];
      }
    }
  };

  const [selectedDates, setSelectedDates] = useState<string[]>(determineInitialSelectedDates());

  // NOTE: this is suboptimal, but it's a quick fix for now
  useEffect(() => {
    setSelectedDates(determineInitialSelectedDates());
  }, [status]);

  const onDateSelect = (date: string) => {
    if (selectedDates.includes(date)) {
      setSelectedDates(selectedDates.filter((d) => d !== date));
    } else {
      setSelectedDates([...selectedDates, date]);
    }
  };

  if (isLoading || !resolutionDossiersReport) {
    return <TableLoading message={t(tokens.common.loading)} />;
  }

  const determineDates = () => {
    switch (status) {
      case ResolutionDossierStatus.OPEN:
        return resolutionDossiersReport!.datesWithOpenDossiers;
      case ResolutionDossierStatus.HANDLED:
        return resolutionDossiersReport!.datesWithHandledDossiers;
      case ResolutionDossierStatus.AWAITING_RESPONSE:
        return resolutionDossiersReport!.datesWithAwaitingResponseDossiers;
    }
  };

  return (
    <Scrollbar>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell colSpan={10} />
          </TableRow>
        </TableHead>
        <TableBody>
          {determineDates()
            .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
            .map((date) => (
              <Fragment key={date}>
                <DateTableListDivider
                  date={date}
                  onClick={() => onDateSelect(date)}
                />

                {selectedDates.includes(date) && (
                  <ResolutionDossierListTableRows
                    date={date}
                    status={status}
                    selectedDossierId={resolutionDossier?.id || null}
                    selectedCaseId={resolutionCaseId || null}
                    selectedStatus={selectedStatus}
                  />
                )}
              </Fragment>
            ))}
        </TableBody>
      </Table>
    </Scrollbar>
  );
};
