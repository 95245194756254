import { tokens } from '../../../../locales/translationTokens';
import { t } from 'i18next';
import { ThreadMessageView } from './ThreadMessageView';
import { TableLoading } from '../../../../components/TableLoading';
import { TableEmpty } from '../../../../components/TableEmpty';
import ThreadMessage from '../../../../domain/automator/messages/ThreadMessage';
import { useFetchThreadMessages } from '../../../../api/automator/emails/useFetchThreadMessages';
import { useState } from 'react';
import ResolutionCase from '../../../../domain/automator/resolutionDossiers/ResolutionCase';
import Stack from '@mui/material/Stack';

interface MessageThreadViewProps {
  resolutionCase: ResolutionCase;
}

const MessageThreadView = ({ resolutionCase }: MessageThreadViewProps) => {
  const [showAll, setShowAll] = useState(true);

  const messageThread = resolutionCase.messageThread;

  const { data: messages, isLoading } = useFetchThreadMessages(messageThread.id);

  if (isLoading || !messages) {
    return <TableLoading message={t(tokens.common.loading)} />;
  }

  if (messages!.messages.length == 0) {
    return <TableEmpty message={t(tokens.automator.resolutions.no_items)} />;
  }

  return (
    <Stack
      direction="column"
      gap={2}
    >
      {showAll ? (
        messages!.messages
          .sort((a, b) => {
            return new Date(b.createdDateTime).getTime() - new Date(a.createdDateTime).getTime();
          })
          .map((messages: ThreadMessage, index: number) => (
            <ThreadMessageView
              key={index}
              message={messages}
              showAll={showAll}
              setShowAll={setShowAll}
            />
          ))
      ) : (
        <ThreadMessageView
          message={messages!.messages[0]}
          showAll={showAll}
          setShowAll={setShowAll}
        />
      )}
    </Stack>
  );
};

export default MessageThreadView;
