import ThreadMessage from '../../../../domain/automator/messages/ThreadMessage'; // @ts-ignore
import DOMPurify from 'dompurify';
import Stack from '@mui/material/Stack';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import { ActionIcon } from '../../orders/components/ActionIcon';
import { KeyboardDoubleArrowDown, KeyboardDoubleArrowUp } from '@mui/icons-material';
import parse from 'react-html-parser';
import { tss } from 'tss-react/mui-compat';
import Typography from '@mui/material/Typography';
import { getDateOnly } from '../../../../helpers/getDateOnly';
import { getTimeStringFromDate } from '../../../../helpers/getTimeStringFromDate';
import { colors } from '@mui/material';
import SenderTypeChip from './SenderTypeChip';
import { SenderType } from '../../../../domain/automator/messages/SenderType';

interface ThreadMessageViewProps {
  message: ThreadMessage;
  showAll: boolean;
  setShowAll: (showAll: boolean) => void;
}

export const ThreadMessageView = ({ message, showAll, setShowAll }: ThreadMessageViewProps) => {
  const { t } = useTranslation();

  const { classes, cx } = useStyles();

  const determineMailBackgroundColor = (senderType: SenderType) => {
    switch (senderType) {
      case SenderType.SELLER:
        return colors.green[50];
      case SenderType.CUSTOMER:
        return colors.cyan[50];
      case SenderType.BOL:
        return colors.blue[50];
      case SenderType.TRANSPORTER:
        return colors.lightBlue[50];
      default:
        return colors.grey[50];
    }
  };

  return (
    <Stack
      direction="row"
      width="100%"
      paddingY={2}
    >
      <Stack
        direction="column"
        gap={1}
        width={70}
      >
        <Stack
          direction="row"
          gap={1}
          alignItems="center"
        >
          {!showAll ? (
            <ActionIcon
              tooltip={t(tokens.automator.resolutions.show_all)}
              icon={<KeyboardDoubleArrowDown />}
              onClick={() => setShowAll(!showAll)}
            />
          ) : (
            <ActionIcon
              tooltip={t(tokens.automator.resolutions.show_last)}
              icon={<KeyboardDoubleArrowUp />}
              onClick={() => setShowAll(!showAll)}
            />
          )}

          <SenderTypeChip senderType={message.senderType} />
        </Stack>

        <Stack
          direction="column"
          gap={0.1}
        >
          <Typography
            fontSize={10}
            fontWeight={500}
            variant={'body1'}
            align={'right'}
          >
            {getDateOnly(message.sentDateTime || message.createdDateTime)}
          </Typography>

          <Stack
            direction="row"
            gap={0.1}
            justifyContent="flex-end"
          >
            <Typography
              fontSize={10}
              fontWeight={500}
              variant={'body1'}
            >
              {getTimeStringFromDate(new Date(message.sentDateTime || message.createdDateTime))}
            </Typography>

            <Typography
              fontSize={10}
              fontWeight={500}
              variant={'body1'}
            >
              {message.medium}
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      <Stack
        direction="column"
        width="100%"
        marginLeft={2}
      >
        <div className={cx(classes.emailMessage)}>
          {parse(
            DOMPurify.sanitize(`
          <div className={cx(classes.custom)} style="font-family: Arial, sans-serif; font-size: 14px; line-height: 1.0; border-radius: 30px; color: #333; padding: 35px 35px 35px 35px; max-width: 100%; margin: 0 0; background-color: ${determineMailBackgroundColor(
            message.senderType
          )};">
            ${message.body}
          </div>
        `)
          )}
        </div>
      </Stack>
    </Stack>
  );
};

const useStyles = tss.create(() => ({
  emailMessage: {
    '& p': {
      lineHeight: '1.3',
      margin: '0 0',
    },
    '& br': {
      lineHeight: '1',
    },
  },
}));
