import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import { useFetchOrderMessageThreads } from '../../../../api/automator/emails/useFetchOrderMessageThreads';
import Stack from '@mui/material/Stack';
import { InitialEmailMessageOpenedIndicator } from './InitialEmailMessageOpenedIndicator';
import { SlimTableCell } from '../../../../components/SlimTableCell';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TriggerTypeChip from '../../resolutions/components/TriggerTypeChip';
import { useFetchOrderResolutionCases } from '../../../../api/automator/emails/useFetchOrderResolutionCases';
import { getDateOnly } from '../../../../helpers/getDateOnly';
import ResolutionCaseStatusChip from '../../resolutions/components/ResolutionCaseStatusChip';
import { usePaths } from '../../../../routes/usePaths';

interface OrderSummaryMessageThreadsProps {
  orderId: number;
}

export const OrderSummaryMessageThreads = ({ orderId }: OrderSummaryMessageThreadsProps) => {
  const { data: messageThreads, isLoading: messageThreadsIsLoading } =
    useFetchOrderMessageThreads(orderId);

  const { data: resolutionCases, isLoading: resolutionCasesIsLoading } =
    useFetchOrderResolutionCases(orderId);

  const path = usePaths();

  if (messageThreadsIsLoading || resolutionCasesIsLoading) {
    return <>...loading</>;
  }

  const sortedMessageThreads = messageThreads?.sort((a, b) => {
    return new Date(b.createdDateTime).getTime() - new Date(a.createdDateTime).getTime();
  });

  return (
    <Table>
      {sortedMessageThreads?.map((messageThread) => {
        const resolutionCase = resolutionCases?.find(
          (resolutionCase) => resolutionCase.messageThreadId === messageThread.id
        );

        return (
          <TableRow
            style={{ cursor: resolutionCase ? 'pointer' : undefined }}
            hover
            key={messageThread.id}
            onClick={
              resolutionCase
                ? () => {
                    const url =
                      path.automator.resolution_dossiers +
                      '/' +
                      resolutionCase.resolutionDossierId +
                      '/cases/' +
                      resolutionCase.id;
                    window.open(url, '_blank');
                  }
                : undefined
            }
          >
            <SlimTableCell>
              <Typography
                variant="body2"
                color="textSecondary"
                noWrap
              >
                {getDateOnly(messageThread.createdDateTime)}
              </Typography>
            </SlimTableCell>
            <SlimTableCell>
              <Stack
                direction="column"
                gap={0.5}
              >
                <Box>
                  <TriggerTypeChip
                    minutesAfterMessageRequiringReply={
                      resolutionCase?.minutesAfterMessageRequiringReply || null
                    }
                    triggerType={messageThread.triggerType}
                  />
                </Box>
                <Typography noWrap>{messageThread.subject}</Typography>
              </Stack>
            </SlimTableCell>
            <SlimTableCell>
              <Stack
                direction="row"
                gap={2}
              >
                {messageThread.initialMessageOpenedDateTime && (
                  <InitialEmailMessageOpenedIndicator
                    initialEmailMessageOpenendDateTime={messageThread.initialMessageOpenedDateTime}
                  />
                )}

                {resolutionCase && (
                  <ResolutionCaseStatusChip resolutionCaseStatus={resolutionCase.status} />
                )}
              </Stack>
            </SlimTableCell>
          </TableRow>
        );
      })}
    </Table>
  );
};
