import { tokens } from '../../../locales/translationTokens';
import AccountPage from '../../../components/AccountPage';
import { useEffect, useState } from 'react';
import { ResolutionDossierStatus } from '../../../domain/automator/resolutionDossiers/ResolutionDossierStatus';
import ResolutionDossierTabs from './components/ResolutionDossierTabs';
import { ResolutionDossierListTable } from './components/ResolutionDossierListTable';
import { useFetchResolutionDossiersReport } from '../../../api/automator/resolutions/useFetchResolutionDossiersReport';
import { useFetchResolutionDossier } from '../../../api/automator/resolutions/useFetchResolutionDossier';
import { useParams } from 'react-router';
import { ResolutionCaseStatus } from '../../../domain/automator/resolutionDossiers/ResolutionCaseStatus';

const ResolutionDossiersPage = () => {
  const [currentTab, setCurrentTab] = useState(ResolutionDossierStatus.OPEN);

  const { dossierId } = useParams();

  const { caseId } = useParams();

  const { data: resolutionDossiersReport, isLoading: isLoadingResolutionDossierReport } =
    useFetchResolutionDossiersReport();

  const { data: resolutionDossier, isLoading: isLoadingResolutionDossier } =
    useFetchResolutionDossier(dossierId ? (dossierId as unknown as number) : undefined);

  useEffect(() => {
    if (resolutionDossier && caseId) {
      const resolutionCase = resolutionDossier.resolutionCases.find(
        (resolutionCase) => resolutionCase.id == (caseId as unknown as number)
      );

      const determineStatus = (status: ResolutionCaseStatus) => {
        switch (status) {
          case ResolutionCaseStatus.OPEN:
            return ResolutionDossierStatus.OPEN;
          case ResolutionCaseStatus.HANDLED:
            return ResolutionDossierStatus.HANDLED;
          case ResolutionCaseStatus.AWAITING_RESPONSE:
            return ResolutionDossierStatus.AWAITING_RESPONSE;
          default:
            return currentTab;
        }
      };

      setCurrentTab(determineStatus(resolutionCase!.status));
    }
  }, [resolutionDossier]);

  if (!resolutionDossiersReport) {
    return <>Loading...</>;
  }

  return (
    <AccountPage title={tokens.automator.resolutions.resolution_dossiers}>
      <ResolutionDossierTabs
        report={resolutionDossiersReport ? resolutionDossiersReport : null}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
      />

      <ResolutionDossierListTable
        isLoading={isLoadingResolutionDossierReport || isLoadingResolutionDossier}
        resolutionDossiersReport={resolutionDossiersReport ? resolutionDossiersReport : null}
        resolutionCaseId={caseId ? (caseId as unknown as number) : null}
        status={currentTab}
        resolutionDossier={resolutionDossier || null}
        selectedStatus={currentTab}
      />
    </AccountPage>
  );
};

export default ResolutionDossiersPage;
